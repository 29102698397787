import { helpers, required } from 'vuelidate/lib/validators';
export const mail = helpers.regex(
  'mail',
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const userValid = {
  firstName: { required },
  lastName: { required },
  email: { required, mail }
};
