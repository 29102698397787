


















































































































































































import Loading from '@/shared/components/Loading.vue';
import { RolesService } from '@/shared/services/roles/roles.service';
import { UserManagementService } from '@/shared/services/user-management/user-management.service';
import { DivisionModule, UserManagementModule } from '@/store';
import { ToastHelper } from '@/utils/toast.util';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Prop } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';

import { userValid } from '../@types/user.model';
Component.registerHooks(['validations']);
@Component({
  components: {
    Loading
  },
  mixins: [validationMixin]
})
export default class UserModal extends Vue {
  userManagementModule = UserManagementModule;

  @Prop() userId: any;
  user = null;
  loading = false;

  divisionModule = DivisionModule;

  roleOptions = [];

  firstPageOptions = [
    { text: 'IMP', value: 'imp' },
    { text: 'EXP', value: 'exp' },
    { text: 'VAN', value: 'van' }
  ];

  divOptions = [];
  selectedDivisions = [];
  allSelected = false;
  indeterminate = false;

  errorModal = false;

  form = this.user;

  constructor() {
    super();
  }

  async created() {
    if (this.userId) {
      //this.user = this.userManagementModule.getUserById(this.userId);
      this.user = this.userManagementModule.users.find(
        a => a.id === this.userId
      );
      this.selectedDivisions = this.user.divisions;
    } else {
      this.user = {
        divisions: null,
        active: true,
        site: '',
        role: null
      };
    }

    this.divOptions.push(
      ...DivisionModule.divisionsList.map(i => ({
        value: i,
        text: i
      }))
    );

    const data = await RolesService.getRolesTableData();
    if (data) {
      this.roleOptions.push(
        ...data.map(i => ({ value: i.role, text: i.role }))
      );
    }
    this.form = this.user;
  }

  toggleAll(checked) {
    this.selectedDivisions = checked
      ? this.divOptions.map(a => a.value).slice()
      : [];
  }

  @Watch('selectedDivisions', { deep: true, immediate: true })
  onSelectionChange(newValue, oldValue) {
    if (newValue.length === 0) {
      this.indeterminate = false;
      this.allSelected = false;
    } else if (newValue.length === this.divOptions.length) {
      this.indeterminate = false;
      this.allSelected = true;
    } else {
      this.indeterminate = true;
      this.allSelected = false;
    }
  }

  async createUser() {
    if (this.isFormInValid()) return;
    this.user.divisions = this.selectedDivisions;
    UserManagementModule.setLoadingStatus(true);
    try {
      if (this.userId) {
        const userToupdate = {
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          email: this.user.email,
          phone: this.user.phone,
          role: this.user.role,
          site: this.user.site,
          divisions: this.selectedDivisions
        };
        await UserManagementService.updateUser(this.user.id, userToupdate);
      } else {
        await UserManagementService.createUser(this.user);
      }
      this.userManagementModule.search();
    } catch (err) {
      console.log('user creation Error', err);
      ToastHelper.show('User', err.response.data?.error, 5000, 'danger');
    } finally {
      this.closeUser();
    }
    UserManagementModule.setLoadingStatus(false);
  }

  closeUser() {
    this.$bvModal.hide('user-modal');
  }

  validateState(val: any) {
    if (this.$v && this.$v.form && this.$v.form[val]) {
      const { $dirty, $error }: any = this.$v.form[val];
      return $dirty ? !$error : null;
    }
  }

  get requiredFields(): Set<string> {
    return new Set(
      Object.keys(userValid).filter(fieldName => userValid[fieldName].required)
    );
  }
  isFormInValid() {
    this.$v.form.$touch();

    if (this.$v.form.$anyError) {
      this.errorModal = true;
    }

    return this.$v.form.$anyError;
  }

  validations() {
    return {
      form: userValid
    };
  }
}
